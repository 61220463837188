.projeto-imagem {
  width: 200px;
  height: 80px;
  border-radius: 10px !important;
  object-fit: cover;
}

.projeto-imagem-pequena {
  width: 100px;
  height: 50px;
  border-radius: 10px !important;
  object-fit: scale-down;
  padding: 5px;
}

.header-justify {
  display: flex;
  justify-content: space-between;
}
